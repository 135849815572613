<template>
  <div class="w-full container">
    <iframe
      class="w-full h-full"
      :src="pdfUrl"
      seamless="seamless"
      scrolling="no"
      frameborder="0"
      allowtransparency="true"
      allowfullscreen="true"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
export default {
  name: 'Pdf',
  created () {
    this.initPage()
  },
  computed: {
    ...mapState('pdf', ['pdfId']),
    pdfUrl () {
      return `https://drive.google.com/file/d/${this.pdfId}/preview`
    }
  },
  methods: {
    ...mapActions('pdf', ['fetch']),
    initPage () {
      this.fetch()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  height: 78vh;
}
</style>
